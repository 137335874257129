import AppBar from "@/views/admin_components/AppBar/AppBar.vue";
import {Api} from "@/services/api";
import NavDrawer from "@/views/admin_components/NavDrawer/NavDrawer.vue";

export default {
    name: 'Programs',
    components: {AppBar, NavDrawer},
    data: () => ({
        programs: [],
    }),
    async created() {
        this.programs = await Api.get('admin/getPrograms')
    }
}