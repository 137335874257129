import AppBar from "@/views/admin_components/AppBar/AppBar.vue";
import CycleNavDrawer from "@/views/admin_components/CycleNavDrawer/CycleNavDrawer.vue";
import {Api} from "@/services/api";

export default {
    name: 'Mentees',
    components: {AppBar, CycleNavDrawer},
    data: () => ({
        mentees: [],
    }),
    async created() {
        this.mentees = await Api.get(`admin/getMentees?cycleId=${this.$route.params.cycleId}`)
    }
}