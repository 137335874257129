import AppBar from "@/views/admin_components/AppBar/AppBar.vue";
import CycleNavDrawer from "@/views/admin_components/CycleNavDrawer/CycleNavDrawer.vue";
import {Api} from "@/services/api";

export default {
    name: 'ManualMatching',
    components: {AppBar, CycleNavDrawer},
    data: () => ({
        mentees: [],
        mentors: [],
        coordinators: [],
        menteeId: null,
        mentorId: null,
        coordinatorId: null,
        isCreating: false
    }),
    created() {
        this.loadUsers()
    },
    methods: {
        loadUsers: async function () {
            const users = await Api.get(`admin/getOddUsers?cycleId=${this.$route.params.cycleId}`)
            this.mentees = users.mentees
            this.mentors = users.mentors
            this.coordinators = users.coordinators
        },
        createSession: async function () {
            if (this.mentorId === null || this.menteeId === null || this.coordinatorId === null) {
                return
            }

            this.isCreating = true

            await Api.post(`admin/createSessions?cycleId=${this.$route.params.cycleId}`, [{
                menteeId: this.menteeId,
                mentorId: this.mentorId,
                coordinatorId: this.coordinatorId
            }])

            this.isCreating = false
            this.menteeId = null
            this.mentorId = null
            this.coordinatorId = null

            window.alert('Session Created')
        }
    }
}