<template>
  <div>
    <app-bar></app-bar>
    <v-list two-line>
      <template v-for="session in sessions">
        <v-list-item
            :key="session.mentee.id"
            :to="`/messages/send?sessionId=${session.id}&recipientId=${session.mentee.id}`"
        >
          <v-list-item-avatar>
            <v-avatar color="primary" size="40">
              <span style="color: white">{{ session.mentee.name.charAt(0) }}</span>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-html="session.mentee.name"></v-list-item-title>
            <v-list-item-subtitle>Mentee</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            :key="session.coordinator.id"
            :to="`/messages/send?sessionId=${session.id}&recipientId=${session.coordinator.id}`"
        >
          <v-list-item-avatar>
            <v-avatar color="primary" size="40">
              <span style="color: white">{{ session.coordinator.name.charAt(0) }}</span>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ session.coordinator.name }}</v-list-item-title>
            <v-list-item-subtitle>Coordinator</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider :key="`div-${session.id}`" inset></v-divider>

      </template>
    </v-list>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>

import AppBar from "@/views/member_components/AppBar.vue";
import BottomBar from "@/views/member_components/BottomBar.vue";
import {Api} from "@/services/api";

export default {
  name: 'Threads',
  components: {AppBar, BottomBar},
  data: () => ({
    sessions: [],
  }),
  async created() {
    this.sessions = await Api.get('mentor/getSessions')
  }
}
</script>
