import {Auth} from "@/services/auth";

export default {
    name: 'AppBar',
    data: () => ({
        name: Auth.name,
        email: Auth.email
    }),
    methods: {
        logOut: function () {
            Auth.clear()
            this.$router.replace('/')
        }
    }
}