export class Auth {

    static ROLE_MENTEE = 1;
    static ROLE_MENTOR = 2;
    static ROLE_COORDINATOR = 4;
    static ROLE_ADMIN = 8;

    static name = null

    static email = null

    static role = null

    static token = null

    static setDetails(name, email, role, token) {
        this.name = name
        this.email = email
        this.role = role
        this.token = token

        localStorage.setItem('account', JSON.stringify({name, email, role, token}))
    }

    static loadDetails() {
        const serializedAccount = localStorage.getItem('account')

        if (serializedAccount === null) {
            return
        }

        const account = JSON.parse(serializedAccount)

        this.name = account.name
        this.email = account.email
        this.role = account.role
        this.token = account.token
    }

    static isMentee = () => this.role === this.ROLE_MENTEE;

    static isMentor = () => this.role === this.ROLE_MENTOR;

    static isCoordinator = () => this.role === this.ROLE_COORDINATOR;

    static isAdmin = () => this.role === this.ROLE_ADMIN;

    static clear() {
        this.name = null
        this.email = null
        this.role = null
        this.token = null
        localStorage.removeItem('account')
    }
}
