import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from "@/views/member_pages/HomeView.vue";
import LogInView from "@/views/member_pages/LogInView.vue";
import MenteeDashboardView from "@/views/member_pages/MenteeDashboardView.vue";
import MentorDashboardView from "@/views/member_pages/MentorDashboardView.vue";
import CoordinatorDashboardView from "@/views/member_pages/CoordinatorDashboardView.vue";
import ThreadsView from "@/views/member_pages/ThreadsView.vue";
import ResourcesView from "@/views/member_pages/ResourcesView.vue";
import MessagesView from "@/views/member_pages/MessagesView.vue";
import MessageReviewsView from "@/views/member_pages/MessageReviewsView.vue";
import Dashboard from "@/views/admin_pages/Dashboard/Dashboard.vue";
import Cycles from "@/views/admin_pages/Cycles/Cycles.vue";
import AddCycle from "@/views/admin_pages/AddCycle/AddCycle.vue";
import AddProgram from "@/views/admin_pages/AddProgram/AddProgram.vue";
import Programs from "@/views/admin_pages/Programs/Programs.vue";
import Program from "@/views/admin_pages/Program/Program.vue";
import Resources from "@/views/admin_pages/Resources/Resources.vue";
import Admins from "@/views/admin_pages/Admins/Admins.vue";
import Coordinators from "@/views/admin_pages/Coordinators/Coordinators.vue";
import CycleDashboard from "@/views/admin_pages/CycleDashboard/CycleDashboard.vue";
import Sessions from "@/views/admin_pages/Sessions/Sessions.vue";
import Pairing from "@/views/admin_pages/Pairing/Pairing.vue";
import ManualMatching from "@/views/admin_pages/ManualMatching/ManualMatching.vue";
import Mentors from "@/views/admin_pages/Mentors/Mentors.vue";
import Mentees from "@/views/admin_pages/Mentees/Mentees.vue";
import AddResource from "@/views/admin_pages/AddResource/AddResource.vue";
import AuthView from "@/views/member_pages/AuthView.vue";
import ChooseCycleView from "@/views/member_pages/ChooseCycleView.vue";
import CoordinatorCycleDashboardView from "@/views/member_pages/CoordinatorCycleDashboardView.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: HomeView
    },
    {
        path: '/login',
        component: LogInView
    },
    {
        path: '/auth',
        component: AuthView
    },
    {
        path: '/mentee/home',
        component: MenteeDashboardView
    },
    {
        path: '/mentor/home',
        component: MentorDashboardView
    },
    {
        path: '/coordinator/choose-cycle',
        component: ChooseCycleView
    },
    {
        path: '/coordinator/cycles',
        component: CoordinatorCycleDashboardView
    },
    {
        path: '/resources',
        component: ResourcesView
    },
    {
        path: '/messages',
        component: ThreadsView
    },
    {
        path: '/messages/send',
        component: MessagesView
    },
    {
        path: '/messages/review',
        component: MessageReviewsView
    },

    {
        path: '/admin/dashboard',
        component: Dashboard
    },
    {
        path: '/admin/cycles',
        component: Cycles
    },
    {
        path: '/admin/add-cycle',
        component: AddCycle
    },
    {
        path: '/admin/programs',
        component: Programs
    },
    {
        path: '/admin/add-program',
        component: AddProgram
    },
    {
        path: '/admin/programs/:programId',
        component: Program
    },
    {
        path: '/admin/resources',
        component: Resources
    },
    {
        path: '/admin/add-resource',
        component: AddResource
    },
    {
        path: '/admin/members/admins',
        component: Admins
    },
    {
        path: '/admin/members/coordinators',
        component: Coordinators
    },
    {
        path: '/admin/cycles/:cycleId/dashboard',
        component: CycleDashboard
    },
    {
        path: '/admin/cycles/:cycleId/sessions',
        component: Sessions
    },
    {
        path: '/admin/cycles/:cycleId/matching/automatic',
        component: Pairing
    },
    {
        path: '/admin/cycles/:cycleId/matching/manual',
        component: ManualMatching
    },
    {
        path: '/admin/cycles/:cycleId/members/mentors',
        component: Mentors
    },
    {
        path: '/admin/cycles/:cycleId/members/mentees',
        component: Mentees
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
