import {Api} from "@/services/api";
import AppBar from "@/views/admin_components/AppBar/AppBar.vue";
import CycleNavDrawer from "@/views/admin_components/CycleNavDrawer/CycleNavDrawer.vue";

export default {
    name: "Sessions",
    components: {AppBar, CycleNavDrawer},
    data: () => ({
        sessions: []
    }),
    async created() {
        this.sessions = await Api.get(`admin/getSessions?cycleId=${this.$route.params.cycleId}`)
    }
}