export class Color {
    static colors = [
        '#ff1744',
        '#ad1457',
        '#43a047',
        '#039be5',
        '#ff6e40',
        '#009688',
        '#9c27b0',
        '#7cb342'
    ]
}