import NavDrawer from "@/views/admin_components/NavDrawer/NavDrawer.vue";
import AppBar from "@/views/admin_components/AppBar/AppBar.vue";
import {Api} from "@/services/api";

export default {
    name: 'AddProgram',
    components: {AppBar, NavDrawer},
    data: () => ({
        title: null,
        topics: [
            {
                title: null,
                description: null,
                menteeNotes: null,
                mentorNotes: null
            }
        ],
    }),
    methods: {
        addTopic: function () {
            this.topics.push({
                title: null,
                description: null,
                menteeNotes: null,
                mentorNotes: null
            })
        },
        removeTopic: function (index) {
            this.topics.splice(index, 1)
        },
        addProgram: async function () {
            if (!this.title) {
                alert('Please Enter a Program Title')
                return;
            }

            const formData = new FormData();
            formData.append('title', this.title)

            for (const [index, topic] of this.topics.entries()) {
                if (!topic.title || !topic.description || !topic.menteeNotes || !topic.mentorNotes) {
                    alert('Please complete all the fields')
                    return
                }

                formData.append(`topics[${index}][title]`, topic.title)
                formData.append(`topics[${index}][description]`, topic.description)
                formData.append(`topics[${index}][menteeNotes]`, topic.menteeNotes)
                formData.append(`topics[${index}][mentorNotes]`, topic.mentorNotes)
            }

            await Api.postForm('admin/createProgram', formData)

            await this.$router.replace('/admin/programs')
        }
    }
}