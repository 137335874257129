<template>
  <div style="background-color: white">
    <v-app-bar color="primary" elevation="0" dark>
      <v-app-bar-nav-icon>
        <v-avatar color="white" size="40">
          <span class="primary--text text-h6">{{ recipient.name.charAt(0) }}</span>
        </v-avatar>
      </v-app-bar-nav-icon>

      <v-app-bar-title>
        {{ recipient.name }}
      </v-app-bar-title>
    </v-app-bar>

    <v-container>
      <v-row no-gutters justify="center">
        <v-col cols="12" md="10" lg="10">

          <v-container>
            <v-row no-gutters v-for="message in messages" :key="message.id" style="margin-bottom: 2rem">
              <v-col cols="2" md="1">
                <v-avatar color="primary">
                  <span class="white--text text-h6">{{ message.sender.name.charAt(0) }}</span>
                </v-avatar>
              </v-col>

              <v-col>
                <p style="font-weight: bold; font-size: 1.1rem; margin: 0">{{ message.sender.name }}</p>
                <span style="color: lightslategrey; font-size: 0.9rem; margin: 0">{{ message.sentAt }}</span>
                &nbsp;
                <v-chip small color="orange" text-color="white" v-if="message.status === 0">Pending</v-chip>
                <v-chip small color="red" text-color="white" v-if="message.status === 2">Rejected</v-chip>
                <div class="trix-content" v-html="message.content" style="margin-top: 0.5rem"></div>
                <v-alert
                    style="margin-top: 0.5rem"
                    v-if="message.rejectionComment"
                    outlined
                    color="error"
                >
                  <p style="margin: 0; font-weight: bold">
                    Rejection Comment
                  </p>
                  <p style="margin: 0">{{ message.rejectionComment }}</p>
                </v-alert>
              </v-col>
            </v-row>
          </v-container>

          <div style="margin-bottom: 1rem;">
            <v-select
                style="margin-bottom: 1rem"
                :items="topics"
                v-model="selectedTopicId"
                label="Topic"
                dense
                outlined
                hide-details
            ></v-select>

            <input type="hidden" id="trixEditor"/>
            <trix-editor class="trix-content" input="trixEditor" ref="trixEditor"
                         @trix-attachment-add="addAttachment($event)"></trix-editor>

            <v-btn block depressed color="primary" style="margin-top: 0.5rem;"
                   @click="sendMessage">Send ›
            </v-btn>
          </div>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style>
span.trix-button-group:nth-child(5) {
  display: none;
}
</style>

<script>

import {Api} from "@/services/api";
import {Auth} from "@/services/auth";

export default {
  name: 'Messages',
  components: {},
  data: () => ({
    recipient: {
      name: ''
    },
    messages: [],
    topics: [],
    selectedTopicId: null
  }),
  created() {
    this.loadMessages()
    this.loadTopics()
  },
  methods: {
    async loadTopics() {
      const topics = await Api.get(`message/getTopics`)

      const items = [
        {
          text: 'General',
          value: null
        }
      ]

      topics.forEach(topic => {
        items.push({
          text: `Week ${topic.weekIndex} - ${topic.title}`,
          value: topic.id
        })

        if (topic.isCurrentWeek) {
          this.selectedTopicId = topic.id
        }
      })

      this.topics = items
    },
    async loadMessages() {
      let url = `message/getMessages?recipientId=${this.$route.query.recipientId}`

      if (this.$route.query.sessionId) {
        url += `&sessionId=${this.$route.query.sessionId}`
      }

      const response = await Api.get(url)

      this.recipient = response.recipient

      response.messages.forEach(message => message.sender = message.isSent ? {name: Auth.name} : response.recipient);

      this.messages = response.messages
    },
    sendMessage: async function () {
      const message = document.getElementById('trixEditor').value;

      const body = {
        recipientId: this.$route.query.recipientId,
        content: message
      }

      if (this.selectedTopicId !== null) {
        body.topicId = this.selectedTopicId
      }

      if (this.$route.query.sessionId) {
        body.sessionId = this.$route.query.sessionId
      }

      await Api.post('message/sendMessage', body)

      this.$refs.trixEditor.editor.loadJSON(JSON.parse('{"document": []}'))

      await this.loadMessages()

      this.$nextTick(() => {
        window.scrollTo(0, document.body.scrollHeight);
      })

    },
    addAttachment: function (event) {
      if (!event.attachment.file) {
        return
      }

      const formData = new FormData();
      formData.append("file", event.attachment.file)

      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${process.env.VUE_APP_API_BASE_PATH}/message/uploadAttachment`, true)
      xhr.setRequestHeader('authorization', Auth.token)

      xhr.upload.addEventListener('progress', function (progressEvent) {
        event.attachment.setUploadProgress(progressEvent.loaded / progressEvent.total * 100)
      })

      xhr.addEventListener('load', () => {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);

          const attributes = {
            url: `${process.env.VUE_APP_API_BASE_PATH}/getFile?token=${response.fileToken}`,
            href: `${process.env.VUE_APP_API_BASE_PATH}/getFile?token=${response.fileToken}`,
          };

          event.attachment.setAttributes(attributes)
        }
      })

      xhr.send(formData)
    }
  }
}
</script>
