<template>
  <div>
    <app-bar></app-bar>

    <v-container fluid>
      <v-row justify="space-between" style="margin-left: 0.2rem; margin-right: 0.2rem">
        <v-col md="3">
          <v-card color="#00897b" dark style="padding: 1rem; height: 100%">
            <p style="font-size: 1.5rem">
              {{ cycle.program.title }}
            </p>

            <v-chip outlined label>
              <v-icon left size="20">
                mdi-calendar-month
              </v-icon>
              <p style="margin-bottom: 0">{{ cycle.startsAt }} - {{ cycle.endsAt }}</p>
            </v-chip>
          </v-card>
        </v-col>

        <v-col md="2">
          <material-stats-card
              style="margin-top: 2rem"
              icon="mdi-lightbulb-group"
              sub-text="Sessions"
              :value="cycle.sessionsCount"
              color="primary"
          />
        </v-col>

        <v-col md="2">
          <material-stats-card
              style="margin-top: 2rem"
              icon="mdi-check-decagram"
              sub-text="Approved Messages"
              :value="cycle.approvedMessagesCount"
              color="#4caf50"
          />
        </v-col>

        <v-col md="2">
          <material-stats-card
              style="margin-top: 2rem"
              icon="mdi-alert-circle"
              sub-text="Rejected Messages"
              :value="cycle.rejectedMessagesCount"
              color="#ef5350"
          />
        </v-col>

        <v-col md="2">
          <material-stats-card
              style="margin-top: 2rem"
              icon="mdi-timer-sand"
              sub-text="Pending Messages"
              :value="cycle.pendingMessagesCount"
              color="#ffa726"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-simple-table style="margin-top: 2rem">
      <template v-slot:default>
        <thead>
        <tr>
          <th></th>

          <th
              v-for="topic in cycle.program.topics"
              :key="topic.id"
              style="text-align: center; font-size: 1rem; color: #5c2684"
              :style="[ topic.isCurrentWeek ? { backgroundColor: '#f3e5f5'  } : {} ]"
          >
            Week {{ topic.weekIndex }}
          </th>

          <th
              style="text-align: center; font-size: 1rem; color: #5c2684; border-left: 1px solid #cfd8dc"
              colspan="2"
          >
            Pending
          </th>
          <th style="text-align: center; border-left: 1px solid #cfd8dc"></th>
        </tr>
        </thead>
        <tbody>

        <template v-for="session in cycle.sessions">
          <tr>
            <td>
              <span style="font-size: 1rem; color: #455a64;font-weight: bold">{{ session.mentor.name }}</span>
              <span style="font-size: 0.8rem; color: #607d8b;">&nbsp;&nbsp;•&nbsp;&nbsp;Mentor</span>
            </td>

            <td
                v-for="topic in session.mentor.topics"
                :key="topic.id"
                style="text-align: center"
                :style="[ topic.isCurrentWeek ? { backgroundColor: '#f3e5f5'  } : {} ]"
            >
              <v-avatar :color="getStatColor(topic)" size="30">
                <span style="color: white; font-size: 0.8rem; font-weight: bold">{{ topic.messagesSentCount }}</span>
              </v-avatar>
            </td>

            <td style="border-left: 1px solid #cfd8dc">
              <v-btn depressed color="#ec407a" small :to="`/messages/review?sessionId=${session.id}`">
                <v-icon left color="white">
                  mdi-comment-search
                </v-icon>
                <span style="color: white">Review</span>
              </v-btn>
            </td>

            <td style="text-align: center">
              <v-avatar size="30" :color="getPendingAvatarColor(session.mentor.pendingMessagesCount)">
                <span style="color: white; font-size: 0.8rem; font-weight: bold">
                  {{ session.mentor.pendingMessagesCount }}
                </span>
              </v-avatar>
            </td>

            <td style="text-align: center; border-left: 1px solid #cfd8dc">
              <v-btn
                  depressed
                  color="primary"
                  small
                  :to="`/messages/send?sessionId=${session.id}&recipientId=${session.mentor.id}&cycleId=${cycle.id}`"
              >
                <v-icon left>
                  mdi-message-text
                </v-icon>
                Message
              </v-btn>
            </td>
          </tr>

          <tr>
            <td>
              <span style="font-size: 1rem; color: #455a64;font-weight: bold">{{ session.mentee.name }}</span>
              <span style="font-size: 0.8rem; color: #607d8b;">&nbsp;&nbsp;•&nbsp;&nbsp;Mentee</span>
            </td>

            <td
                v-for="topic in session.mentee.topics"
                :key="topic.id"
                style="text-align: center"
                :style="[ topic.isCurrentWeek ? { backgroundColor: '#f3e5f5'  } : {} ]"
            >
              <v-avatar :color="getStatColor(topic)" size="30">
                <span style="color: white; font-size: 0.8rem; font-weight: bold">{{ topic.messagesSentCount }}</span>
              </v-avatar>
            </td>

            <td style="border-left: 1px solid #cfd8dc">
              <v-btn depressed color="#ec407a" small :to="`/messages/review?sessionId=${session.id}`">
                <v-icon left color="white">
                  mdi-comment-search
                </v-icon>
                <span style="color: white">Review</span>
              </v-btn>
            </td>

            <td style="text-align: center">
              <v-avatar size="30" :color="getPendingAvatarColor(session.mentee.pendingMessagesCount)">
                <span style="color: white; font-size: 0.8rem; font-weight: bold">
                  {{ session.mentee.pendingMessagesCount }}
                </span>
              </v-avatar>
            </td>

            <td style="text-align: center; border-left: 1px solid #cfd8dc">
              <v-btn
                  depressed
                  color="primary"
                  small
                  :to="`/messages/send?sessionId=${session.id}&recipientId=${session.mentee.id}&cycleId=${cycle.id}`"
              >
                <v-icon left>
                  mdi-message-text
                </v-icon>
                Message
              </v-btn>
            </td>
          </tr>

          <tr>
            <td colspan="12" style="height: 1px">
              <div style="width: 100%; height: 1px; background-color: #5c2684; margin: 0"></div>
            </td>
          </tr>
        </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import AppBar from "@/views/member_components/AppBar.vue";
import BottomBar from "@/views/member_components/BottomBar.vue";
import {Api} from "@/services/api";
import MaterialStatsCard from "@/views/card/MaterialStatsCard.vue";

export default {
  name: 'CoordinatorCycleDashboard',
  components: {BottomBar, AppBar, MaterialStatsCard},
  data: () => ({
    cycle: {
      program: {
        topics: []
      },
      sessions: []
    },
  }),
  async created() {
    this.cycle = await Api.get(`coordinator/getCycleDashboard?cycleId=${this.$route.query.cycleId}`)
  },
  methods: {
    getStatColor: function (topic) {
      if (topic.isPastWeek) {
        return topic.messagesSentCount === 0 ? '#ef5350' : '#4caf50';
      }

      if (topic.isCurrentWeek) {
        return topic.messagesSentCount === 0 ? '#ffa726' : '#4caf50'
      }

      return '#4caf50'
    },
    getPendingAvatarColor(pendingCount) {
      if (pendingCount === 0) {
        return '#4caf50'
      }

      return '#ffa726'
    }
  }
}
</script>
