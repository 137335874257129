import NavDrawer from "@/views/admin_components/NavDrawer/NavDrawer.vue";
import AppBar from "@/views/admin_components/AppBar/AppBar.vue";
import {Api} from "@/services/api";
import MaterialStatsCard from "@/views/card/MaterialStatsCard.vue";

export default {
    name: "Dashboard",
    components: {AppBar, NavDrawer, MaterialStatsCard},
    data: () => ({
        dashboard: {},
    }),
    async created() {
        this.dashboard = await Api.get('admin/getDashboard')
    },
}