export default {
    name: 'CycleNavDrawer',
    data: () => ({
        routePrefix: `/admin/cycles/`,
        menuItems: [
            {
                title: 'Dashboard',
                icon: 'mdi-view-dashboard',
                to: 'dashboard',
                hasSubItems: false,
            },
            {
                title: 'Sessions',
                icon: 'mdi-lightbulb-group',
                to: 'sessions',
                hasSubItems: false,
            },
            {
                title: 'Matching',
                icon: 'mdi-account-multiple-plus',
                hasSubItems: true,
                subItems: [
                    {
                        title: 'Automatic',
                        icon: 'mdi-cog',
                        to: 'matching/automatic',
                    },
                    {
                        title: 'Manual',
                        icon: 'mdi-cursor-default-click',
                        to: 'matching/manual',
                    }
                ]
            },
            {
                title: 'Members',
                icon: 'mdi-account-group',
                hasSubItems: true,
                subItems: [
                    {
                        title: 'Mentors',
                        icon: 'mdi-human-male-board',
                        to: 'members/mentors',
                    },
                    {
                        title: 'Mentees',
                        icon: 'mdi-account-school',
                        to: 'members/mentees',
                    }
                ]
            },
        ],
    }),
    created() {
        this.routePrefix += `${this.$route.params.cycleId}/`
    }
}