<template>
  <v-bottom-navigation v-if="this.$vuetify.breakpoint.smAndDown" grow fixed color="primary">

    <template v-if="isMentee">
      <v-btn value="home" to="/mentee/home">
        <span>Home</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn value="resources" to="/resources">
        <span>Resources</span>
        <v-icon>mdi-book-open-page-variant</v-icon>
      </v-btn>

      <v-menu
          bottom
          min-width="200px"
          rounded
          offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on">
            <span>Account</span>
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
                  color="brown"
              >
                <span class="white--text text-h5">{{ name[0] }}</span>
              </v-avatar>
              <h3 style="margin-top: 0.5rem">{{ name }}</h3>
              <p class="text-caption mt-1">
                {{ email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                  depressed
                  rounded
                  text
                  @click="logOut"
              >
                Log Out
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>

    </template>

    <template v-if="isMentor">
      <v-btn value="home" to="/mentor/home">
        <span>Home</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn value="resources" to="/resources">
        <span>Resources</span>
        <v-icon>mdi-book-open-page-variant</v-icon>
      </v-btn>

      <v-btn value="mail" to="/mail">
        <span>Mail</span>
        <v-icon>mdi-email</v-icon>
      </v-btn>

      <v-menu
          bottom
          min-width="200px"
          rounded
          offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on">
            <span>Account</span>
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
                  color="brown"
              >
                <span class="white--text text-h5">{{ name[0] }}</span>
              </v-avatar>
              <h3 style="margin-top: 0.5rem">{{ name }}</h3>
              <p class="text-caption mt-1">
                {{ email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                  depressed
                  rounded
                  text
                  @click="logOut"
              >
                Log Out
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </template>

    <template v-else-if="isCoordinator">
      <v-btn value="home" to="/coordinator/home">
        <span>Home</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn value="resources" to="/mail-review">
        <span>Mail Review</span>
        <v-icon>mdi-email-search</v-icon>
      </v-btn>

      <v-btn value="mail" to="/coordinator/mails">
        <span>Mail</span>
        <v-icon>mdi-email</v-icon>
      </v-btn>

      <v-menu
          bottom
          min-width="200px"
          rounded
          offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on">
            <span>Account</span>
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
                  color="brown"
              >
                <span class="white--text text-h5">{{ name[0] }}</span>
              </v-avatar>
              <h3 style="margin-top: 0.5rem">{{ name }}</h3>
              <p class="text-caption mt-1">
                {{ email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                  depressed
                  rounded
                  text
                  @click="logOut"
              >
                Log Out
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </template>
  </v-bottom-navigation>
</template>

<style>
.v-item-group.v-bottom-navigation .v-btn.v-size--default {
  height: inherit;
}
</style>

<script>
import {Auth} from "@/services/auth";

export default {
  name: 'BottomBar',
  data: () => ({
    isMentee: Auth.isMentee(),
    isMentor: Auth.isMentor(),
    isCoordinator: Auth.isCoordinator(),
    name: Auth.name,
    email: Auth.email
  }),
  methods: {
    logOut: function () {
      Auth.clear()
      this.$router.replace('/')
    }
  }
}
</script>
