import NavDrawer from "@/views/admin_components/NavDrawer/NavDrawer.vue";
import AppBar from "@/views/admin_components/AppBar/AppBar.vue";
import {Api} from "@/services/api";

export default {
    name: 'Cycles',
    components: {AppBar, NavDrawer},
    data: () => ({
        cycles: [],
    }),
    async created() {
        this.cycles = await Api.get('admin/getCycles')
    }
}