<template>
  <div>
    <app-bar></app-bar>

    <bottom-bar></bottom-bar>

    <v-container style="padding: 2rem">
      <v-row>
        <v-col>
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="text-h5">
              {{ dashboard.cycle.program.title }}
            </v-card-title>

            <v-card-subtitle>{{ dashboard.cycle.startsAt }} - {{ dashboard.cycle.endsAt }}</v-card-subtitle>

            <v-card-text>
              <v-chip
                  outlined
                  label
                  color="white"
              >
                {{ dashboard.cycle.approvedMessagesCount }} Approved Messages
              </v-chip>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

              <v-chip
                  outlined
                  label
                  color="white"
              >
                {{ dashboard.cycle.rejectedMessagesCount }} Rejected Messages
              </v-chip>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

              <v-chip
                  outlined
                  label
                  color="white"
              >
                {{ dashboard.cycle.pendingMessagesCount }} Pending Messages
              </v-chip>

              <div style="height: 1rem"></div>

              <v-chip
                  outlined
                  label
                  color="white"
              >
                {{ dashboard.cycle.sessionsCount }} Sessions
              </v-chip>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

              <v-chip
                  outlined
                  label
                  color="white"
              >
                {{ dashboard.cycle.menteesCount }} Mentees
              </v-chip>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

              <v-chip
                  outlined
                  label
                  color="white"
              >
                {{ dashboard.cycle.mentorsCount }} Mentors
              </v-chip>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-timeline v-if="dashboard.cycle" dense>
        <v-timeline-item v-for="topic in dashboard.cycle.program.topics" :key="topic.id">
          <p style="margin-top: 0.5rem">
            <span style="font-weight: bold; size: 1.2rem;">{{ topic.title }}</span>
            &nbsp;
            <v-chip color="primary" small v-if="topic.isCurrentWeek">Current Week</v-chip>
          </p>
          <template v-slot:icon>
            <v-avatar>
              <span style="color: white; font-weight: bold">{{ topic.weekIndex }}</span>
            </v-avatar>
          </template>
        </v-timeline-item>
      </v-timeline>
    </v-container>
  </div>
</template>

<script>
import AppBar from "@/views/member_components/AppBar.vue";
import BottomBar from "@/views/member_components/BottomBar.vue";
import {Api} from "@/services/api";

export default {
  name: 'CoordinatorDashboard',
  components: {BottomBar, AppBar},
  data: () => ({
    dashboard: {},
  }),
  async created() {
    this.dashboard = await Api.get(`coordinator/dashboard`)
  },
}
</script>
