<template>
  <div>
    <app-bar></app-bar>
    <v-container fluid style="margin-bottom: 60px">
      <v-row>
        <v-col xs="12" sm="12" md="4">
          <v-card color="#ad1457" dark style="padding: 1rem; height: 100%">
            <p style="font-size: 1.5rem">
              {{ dashboard.cycle.program.title }}
            </p>

            <v-chip outlined label>
              <v-icon left size="20">
                mdi-calendar-month
              </v-icon>
              <p style="margin-bottom: 0">{{ dashboard.cycle.startsAt }} - {{ dashboard.cycle.endsAt }}</p>
            </v-chip>

            <div style="margin-top: 1.5rem">
              <v-chip outlined label color="white">
                {{ getMessagesSentText }}
              </v-chip>

              <v-chip outlined label color="white" style="margin-left: 1rem">
                {{ getMessagesReceivedText }}
              </v-chip>
            </div>
          </v-card>
        </v-col>

        <v-col xs="12" sm="12" md="4">
          <v-card color="#009688" dark style="padding: 1rem; height: 100%">
            <v-chip label color="#009688">
              <v-icon left>
                mdi-human-male-board
              </v-icon>
              <p style="font-size: 1.5rem; margin-bottom: 0">Mentor</p>
            </v-chip>

            <p style="margin-top: 1rem; font-size: 1.2rem">
              {{ dashboard.mentor.name }}
            </p>

            <v-btn
                depressed
                color="white"
                style="margin-top: 0.5rem"
                :to="`/messages/send?recipientId=${dashboard.mentor.id}`"
            >
              <v-icon left color="#009688">
                mdi-message-text
              </v-icon>
              <span style="color: #009688">Message</span>
            </v-btn>
          </v-card>
        </v-col>

        <v-col xs="12" sm="12" md="4">
          <v-card color="#039be5" dark style="padding: 1rem; height: 100%">
            <v-chip label color="#039be5">
              <v-icon left>
                mdi-account-network
              </v-icon>
              <p style="font-size: 1.5rem; margin-bottom: 0">Coordinator</p>
            </v-chip>

            <p style="margin-top: 1rem; font-size: 1.2rem">
              {{ dashboard.coordinator.name }}
            </p>

            <v-btn
                depressed
                color="white"
                style="margin-top: 0.5rem"
                :to="`/messages/send?recipientId=${dashboard.coordinator.id}`"
            >
              <v-icon left color="#039be5">
                mdi-message-text
              </v-icon>
              <span style="color: #039be5">Message</span>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            v-for="(topic,index) in dashboard.cycle.program.topics"
            :key="index"
            xs="12"
            sm="12"
            md="3"
        >
          <v-card elevation="1" style="height: 100%">
            <p style="padding-left: 1rem; padding-top: 0.5rem; padding-bottom: 0.5rem; font-weight: normal; color: white; font-size: 1.3rem"
               :style="{ backgroundColor: colors[index] }">
              {{ `${topic.weekIndex}.  ${topic.title}` }}
            </p>

            <div style="padding-left: 1rem; padding-right: 1rem; padding-bottom: 1rem">
              <p :style="{ color: topic.canView ? 'black' : 'grey' }">{{ topic.description }}</p>

              <v-btn
                  :disabled="!topic.canView"
                  text
                  color="primary"
                  href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
              >
                <v-icon left>mdi-download</v-icon>
                Download Notes
              </v-btn>

              <v-chip color="primary" small v-if="topic.isCurrentWeek">Current Week</v-chip>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>
import AppBar from "@/views/member_components/AppBar.vue";
import BottomBar from "@/views/member_components/BottomBar.vue";
import {Api} from "@/services/api";
import {Color} from "@/services/color";

export default {
  name: 'MenteeDashboard',
  components: {AppBar, BottomBar},
  data: () => ({
    dashboard: {
      messagesSentCount: 0,
      messagesReceivedCount: 0,
      mentor: {
        name: 'Loading...'
      },
      coordinator: {
        name: 'Loading...'
      },
      cycle: {
        program: {
          title: 'Loading...',
          topics: []
        }
      }
    },
    colors: Color.colors
  }),
  async created() {
    this.dashboard = await Api.get('mentee/getDashboard')
  },
  computed: {
    getMessagesSentText() {
      if (this.dashboard.messagesSentCount === 1) {
        return '1 Message Sent'
      }

      return `${this.dashboard.messagesSentCount} Messages Sent`
    },
    getMessagesReceivedText() {
      if (this.dashboard.messagesReceivedCount === 1) {
        return '1 Message Received'
      }

      return `${this.dashboard.messagesReceivedCount} Messages Received`
    }
  }
}
</script>
