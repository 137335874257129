<template>

</template>

<script>

import {Auth} from "@/services/auth";

export default {
  name: 'Auth',
  mounted() {
    const query = this.$route.query

    Auth.setDetails(query.name, query.email, parseInt(query.role), query.token)

    if (Auth.isMentee()) {
      this.$router.replace('/mentee/home')
      return
    }

    if (Auth.isMentor()) {
      this.$router.replace('/mentor/home')
    }
  },
}
</script>
