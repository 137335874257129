import AppBar from "@/views/admin_components/AppBar/AppBar.vue";
import CycleNavDrawer from "@/views/admin_components/CycleNavDrawer/CycleNavDrawer.vue";
import {Api} from "@/services/api";
import MaterialStatsCard from "@/views/card/MaterialStatsCard.vue";


export default {
    name: "CycleDashboard",
    components: {AppBar, CycleNavDrawer, MaterialStatsCard},
    data: () => ({
        dashboard: {},
    }),
    async created() {
        this.dashboard = await Api.get(`admin/getCycleDashboard?cycleId=${this.$route.params.cycleId}`)
    },
}