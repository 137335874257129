import NavDrawer from "@/views/admin_components/NavDrawer/NavDrawer.vue";
import AppBar from "@/views/admin_components/AppBar/AppBar.vue";
import {Api} from "@/services/api";

export default {
    name: 'AddResource',
    components: {AppBar, NavDrawer},
    data: () => ({
        title: '',
        description: '',
        programs: [
            {
                text: 'None',
                value: -1,
            },
            {
                divider: true
            }
        ],
        programId: -1,
        file: null,
        canMenteesView: false,
    }),
    async created() {
        const programs = await Api.get('admin/getPrograms')
        programs.forEach(program => {
            this.programs.push({
                text: program.title,
                value: program.id
            })
        })
    },
    methods: {
        addResource: async function () {
            if (!this.title || !this.description || !this.file) {
                alert('Information Missing')
                return;
            }

            const formData = new FormData();
            formData.append('title', this.title)
            formData.append('description', this.description)
            formData.append('canMenteesView', this.canMenteesView)
            formData.append('file', this.file)

            if (this.programId !== -1) {
                formData.append('programId', this.programId)
            }

            await Api.postForm('admin/createResource', formData)

            await this.$router.replace('/admin/resources')
        }
    }
}