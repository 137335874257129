import AppBar from "@/views/admin_components/AppBar/AppBar.vue";
import CycleNavDrawer from "@/views/admin_components/CycleNavDrawer/CycleNavDrawer.vue";
import {Api} from "@/services/api";

export default {
    name: "Pairing",
    components: {AppBar, CycleNavDrawer},
    data: () => ({
        stage: 1,
        progress: 0,
        matches: [],
        isAcceptButtonLoading: false
    }),
    methods: {
        startMatching: async function () {
            this.stage = 2;

            const users = await Api.get(`admin/getOddUsers?cycleId=${this.$route.params.cycleId}`)

            const worker = new Worker(new URL('../../../workers/worker.js', import.meta.url));
            worker.postMessage(users);

            worker.onmessage = async ({data}) => {
                if (data.progress) {
                    this.progress = data.progress
                    return
                }

                if (data.results) {
                    this.matches = data.results
                    this.progress = 100
                    this.stage = 3

                    console.log(this.matches)
                }
            };
        },
        async acceptMatches() {
            this.isAcceptButtonLoading = true

            let data = []

            for (let match of this.matches) {
                data.push({menteeId: match.mentee.id, mentorId: match.mentor.id, coordinatorId: match.coordinator.id})
            }

            await Api.post(`admin/createSessions?cycleId=${this.$route.params.cycleId}`, data)

            await this.$router.replace(`/admin/cycles/${this.$route.params.cycleId}/sessions`)
        }
    }
}