<template>
  <v-app-bar
      app
      color="white"
      dark
      elevation="0"
  >
    <v-img
        src="@/assets/logo.png"
        max-height="40"
        max-width="140"
        contain
    ></v-img>

    <template v-if="this.$vuetify.breakpoint.mdAndUp">
      <v-spacer></v-spacer>

      <template v-if="isMentee">
        <v-btn elevation="0" color="primary" to="/mentee/home" :text="this.$route.name !== 'menteeHome'">
          Home
        </v-btn>

        <v-btn elevation="0" color="primary" to="/resources" :text="this.$route.name !== 'resources'">
          Resources
        </v-btn>

        <v-menu
            bottom
            min-width="200px"
            rounded
            offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
                style="margin-left: 0.5rem"
                icon
                v-on="on"
            >
              <v-avatar
                  color="blue"
                  size="36"
              >
                <span style="font-size: 1rem">{{ name[0] }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="brown">
                  <span class="white--text text-h5">{{ name[0] }}</span>
                </v-avatar>

                <h3 style="margin-top: 0.5rem">{{ name }}</h3>

                <p class="text-caption mt-1">{{ email }}</p>

                <v-divider class="my-3"></v-divider>
                <v-btn
                    href="https://elearning.kune4u.com/my/"
                    target="_blank"
                    depressed
                    rounded
                    text
                >
                  Profile
                </v-btn>

                <v-divider class="my-3"></v-divider>
                <v-btn
                    depressed
                    rounded
                    text
                    @click="logOut"
                >
                  Log Out
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>

      </template>

      <template v-if="isMentor">
        <v-btn elevation="0" color="primary" to="/mentor/home" :text="this.$route.name !== 'mentorHome'">
          Home
        </v-btn>

        <v-btn elevation="0" color="primary" to="/resources" :text="this.$route.name !== 'resources'">
          Resources
        </v-btn>

        <v-btn elevation="0" color="primary" to="/messages" :text="this.$route.name !== 'mail'">
          Messages
        </v-btn>

        <v-menu
            bottom
            min-width="200px"
            rounded
            offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
                style="margin-left: 0.5rem"
                icon
                v-on="on"
            >
              <v-avatar
                  color="blue"
                  size="36"
              >
                <span style="font-size: 1rem">{{ name[0] }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="brown">
                  <span class="white--text text-h5">{{ name[0] }}</span>
                </v-avatar>
                <h3 style="margin-top: 0.5rem">{{ name }}</h3>
                <p class="text-caption mt-1">{{ email }}</p>

                <v-divider class="my-3"></v-divider>
                <v-btn
                    href="https://elearning.kune4u.com/my/"
                    target="_blank"
                    depressed
                    rounded
                    text
                >
                  Profile
                </v-btn>

                <v-divider class="my-3"></v-divider>
                <v-btn
                    depressed
                    rounded
                    text
                    @click="logOut"
                >
                  Log Out
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </template>

      <template v-else-if="isCoordinator">
        <v-btn text elevation="0" color="primary" to="/coordinator/choose-cycle">
          Change Cycle
        </v-btn>

        <v-menu bottom min-width="200px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn style="margin-left: 0.5rem" icon v-on="on">
              <v-avatar color="primary" size="36">
                <span style="font-size: 1rem">{{ name[0] }}</span>
              </v-avatar>
            </v-btn>
          </template>

          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="primary"><span class="white--text text-h5">{{ name[0] }}</span></v-avatar>
                <h3 style="margin-top: 0.5rem">{{ name }}</h3>
                <p class="text-caption mt-1">{{ email }}</p>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text @click="logOut()">Log Out</v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </template>
    </template>
  </v-app-bar>
</template>

<script>
import {Auth} from "@/services/auth";

export default {
  name: 'AppBar',
  data: () => ({
    isMentee: Auth.isMentee(),
    isMentor: Auth.isMentor(),
    isCoordinator: Auth.isCoordinator(),
    name: Auth.name,
    email: Auth.email
  }),
  methods: {
    logOut: function () {
      Auth.clear()
      this.$router.replace('/')
    }
  }
}
</script>
