import NavDrawer from "@/views/admin_components/NavDrawer/NavDrawer.vue";
import AppBar from "@/views/admin_components/AppBar/AppBar.vue";
import {Api} from "@/services/api";

export default {
    name: 'AddCycle',
    components: {AppBar, NavDrawer},
    data: () => ({
        programs: [],
        programId: null,
        moodleCourseId: null,
        startsAt: null,
        isDatePickerOpen: false
    }),
    async created() {
        this.programs = await Api.get('admin/getPrograms')
    },
    methods: {
        addCycle: async function () {
            await Api.post('admin/createCycle', {
                programId: this.programId,
                moodleCourseId: this.moodleCourseId,
                startsAt: this.startsAt
            })

            await this.$router.replace('/admin/cycles')
        }
    }
}