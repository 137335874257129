import AppBar from "@/views/admin_components/AppBar/AppBar.vue";
import CycleNavDrawer from "@/views/admin_components/CycleNavDrawer/CycleNavDrawer.vue";
import {Api} from "@/services/api";

export default {
    name: 'Mentors',
    components: {AppBar, CycleNavDrawer},
    data: () => ({
        mentors: [],
    }),
    async created() {
        this.mentors = await Api.get(`admin/getMentors?cycleId=${this.$route.params.cycleId}`)
    }
}