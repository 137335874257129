<template>
  <div>
    <app-bar></app-bar>
    <v-container fluid style="margin-bottom: 60px; padding: 2rem">
      <v-row>
        <v-col
            v-for="(resource, index) in resources.general"
            :key="resource.id"
            cols="12"
            md="4"
        >
          <v-card elevation="1" style="height: 100%">
            <p style="padding-left: 1rem; padding-top: 0.5rem; padding-bottom: 0.5rem; font-weight: normal; color: white; font-size: 1.3rem"
               :style="{ backgroundColor : colors[index] }">
              {{ resource.title }}
            </p>

            <div style="padding-left: 1rem; padding-right: 1rem; padding-bottom: 1rem">

              <p>{{ resource.description }}</p>

              <v-btn
                  text
                  color="primary"
                  target="_blank"
                  :href="`${apiBasePath}/getFile?token=${resource.fileToken}`"
              >
                <v-icon left>mdi-download</v-icon>
                Download
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-divider style="margin-top: 2rem"></v-divider>
      <p style="font-size: 2rem; font-weight: bold; margin-left: 1rem; margin-top: 1rem">{{ resources.program.title }}</p>

      <v-row>
        <v-col
            v-for="(resource, index) in resources.program.resources"
            :key="resource.id"
            cols="12"
            md="4"
        >

          <v-card elevation="1" style="height: 100%">
            <p style="padding-left: 1rem; padding-top: 0.5rem; padding-bottom: 0.5rem; font-weight: normal; color: white; font-size: 1.3rem"
               :style="{ backgroundColor : colors[index] }">
              {{ resource.title }}
            </p>

            <div style="padding-left: 1rem; padding-right: 1rem; padding-bottom: 1rem">

              <p>{{ resource.description }}</p>

              <v-btn
                  text
                  color="primary"
                  target="_blank"
                  :href="`${apiBasePath}/getFile?token=${resource.fileToken}`"
              >
                <v-icon left>mdi-download</v-icon>
                Download
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>
import AppBar from "@/views/member_components/AppBar.vue";
import BottomBar from "@/views/member_components/BottomBar.vue";
import {Api} from "@/services/api";
import {Auth} from "@/services/auth";
import {Color} from "@/services/color";

export default {
  name: 'Resources',
  components: {AppBar, BottomBar},
  data: () => ({
    apiBasePath: process.env.VUE_APP_API_BASE_PATH,
    resources: {
      general: [],
      program: {
        title: 'Loading...',
        resources: []
      }
    },
    colors: Color.colors
  }),
  async created() {
    this.resources = Auth.isMentee() ? await Api.get('mentee/getResources') : await Api.get('mentor/getResources');
  }
}
</script>
