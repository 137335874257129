<template>
  <v-container>
    <v-row justify="center">
      <v-col xs="12" sm="8" md="6">

        <v-img
            src="@/assets/logo.png"
        ></v-img>

        <v-text-field
            style="margin-top: 2rem"
            label="Email"
            outlined
            v-model.trim="email"
        ></v-text-field>

        <v-text-field
            label="Password"
            outlined
            v-model.trim="password"
        ></v-text-field>

        <v-btn depressed color="primary" block @click="logIn">Log In</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {Api} from "@/services/api";
import {Auth} from "@/services/auth";

export default {
  name: 'LogIn',
  data: () => ({
    email: '',
    password: ''
  }),
  methods: {
    logIn: async function () {
      const response = await Api.post('logIn', {
        email: this.email,
        password: this.password
      })

      Auth.setDetails(response.name, response.email, response.role, response.token)

      if (Auth.isCoordinator()) {
        await this.$router.replace('/coordinator/choose-cycle')
      } else if (Auth.isAdmin()) {
        await this.$router.replace('/admin/dashboard')
      }
    }
  }
}
</script>
