import AppBar from "@/views/admin_components/AppBar/AppBar.vue";
import {Api} from "@/services/api";
import NavDrawer from "@/views/admin_components/NavDrawer/NavDrawer.vue";

export default {
    name: 'Program',
    components: {AppBar, NavDrawer},
    data: () => ({
        program: {},
        apiBasePath: process.env.VUE_APP_API_BASE_PATH,
        colors: [
            '#ff1744',
            '#ad1457',
            '#43a047',
            '#039be5',
            '#ff6e40',
            '#009688',
            '#9c27b0',
            '#7cb342'
        ]
    }),
    async created() {
        this.program = await Api.get(`admin/getProgram?programId=${this.$route.params.programId}`)
    }
}