export default {
    name: 'NavDrawer',
    data: () => ({
        menuItems: [
            {
                title: 'Dashboard',
                icon: 'mdi-view-dashboard',
                to: '/admin/dashboard',
                hasSubItems: false,
            },
            {
                title: 'Cycles',
                icon: 'mdi-calendar-sync',
                hasSubItems: false,
                to: '/admin/cycles',
            },
            {
                title: 'Add Cycle',
                icon: 'mdi-calendar-plus',
                to: '/admin/add-cycle',
                hasSubItems: false,
            },
            {
                title: 'Programs',
                icon: 'mdi-book',
                to: '/admin/programs',
                hasSubItems: false,
            },
            {
                title: ' Add Program',
                icon: 'mdi-book-plus',
                to: '/admin/add-program',
                hasSubItems: false,
            },
            {
                title: 'Resources',
                icon: 'mdi-file-document',
                to: '/admin/resources',
                hasSubItems: false,
            },
            {
                title: 'Add Resource',
                icon: 'mdi-file-document-multiple',
                to: '/admin/add-resource',
                hasSubItems: false,
            },
            {
                title: 'Members',
                icon: 'mdi-account-group',
                hasSubItems: true,
                subItems: [
                    {
                        title: 'Admins',
                        icon: 'mdi-shield-account',
                        to: '/admin/members/admins',
                    },
                    {
                        title: 'Coordinators',
                        icon: 'mdi-account-network',
                        to: '/admin/members/coordinators',
                    }
                ]
            },
        ],
    }),
}