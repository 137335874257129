<template>
  <div>
    <app-bar></app-bar>
    <v-list two-line>
      <template v-for="cycle in cycles">
        <v-list-item
            :key="cycle.id"
            :to="`/coordinator/cycles?cycleId=${cycle.id}`"
        >
          <v-list-item-content>
            <v-list-item-title v-html="cycle.program.title"></v-list-item-title>
            <v-list-item-subtitle v-html="`${cycle.startsAt} - ${cycle.endsAt}`"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider :key="`divider-${cycle.id}`" inset></v-divider>

      </template>
    </v-list>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>

import AppBar from "@/views/member_components/AppBar.vue";
import BottomBar from "@/views/member_components/BottomBar.vue";
import {Api} from "@/services/api";

export default {
  name: 'ChooseCycle',
  components: {AppBar, BottomBar},
  data: () => ({
    cycles: [],
  }),
  async created() {
    this.cycles = await Api.get('coordinator/getCycles')
  }
}
</script>
