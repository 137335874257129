import {Api} from "@/services/api";
import AppBar from "@/views/admin_components/AppBar/AppBar.vue";
import NavDrawer from "@/views/admin_components/NavDrawer/NavDrawer.vue";

export default {
    name: 'Resources',
    components: {AppBar, NavDrawer},
    data: () => ({
        resources: [],
        apiBasePath: process.env.VUE_APP_API_BASE_PATH,
    }),
    async created() {
        this.resources = await Api.get('admin/getResources')
    }
}