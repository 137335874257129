<template>
  <v-container>
    <v-row justify="center">
      <v-col xs="12" sm="8" md="6">

        <v-img
            style="margin-top: 2rem"
            src="@/assets/logo.png"
        ></v-img>

        <v-btn depressed block to="/login" color="primary" style="margin-top: 4rem">Log In</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Home',
  data: () => ({}),
}
</script>
