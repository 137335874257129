import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import {Auth} from "@/services/auth";

Vue.config.productionTip = false

Auth.loadDetails()

new Vue({
    ignoredElements: ['trix-editor'],
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
