import {Auth} from "@/services/auth";

export class Api {
    static apiBasePath = process.env.VUE_APP_API_BASE_PATH

    static post(path, body = null) {
        return this.request(path, 'POST', body)
    }

    static get(path) {
        return this.request(path)
    }

    static async postForm(path, formData) {
        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: {authorization: Auth.token}
        }

        const response = await fetch(`${this.apiBasePath}/${path}`, requestOptions)

        if (response.status === 200) {
            return await response.json()
        }

        if (response.status === 204) {
            return null
        }

        if (response.status === 400) {
            const error = await response.json()
            // throw new ApiError(error.code)
        }
    }

    static async request(path, method = 'GET', body = null) {
        const request = {
            method: method,
            headers: {authorization: Auth.token}
        }

        if (body !== null) {
            request.headers['accept'] = 'application/json'
            request.headers['content-type'] = 'application/json'
            request.body = JSON.stringify(body)
        }

        const response = await fetch(`${this.apiBasePath}/${path}`, request)

        if (response.status === 200) {
            return await response.json()
        }

        if (response.status === 204) {
            return null
        }

        if (response.status === 400) {
            const error = await response.json()
            // throw new ApiError(error.code)
        }
    }
}