<template>
  <div style="background-color: white">
    <v-app-bar color="primary" elevation="0" dark>
      <p style="margin: 0">
        Review Messages
      </p>
    </v-app-bar>

    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="10" lg="10">

          <v-container>
            <v-row no-gutters v-for="message in messages" :key="message.id" style="margin-bottom: 1rem">
              <v-col cols="2" md="1">
                <v-avatar color="success">
                  <span class="white--text text-h6">{{ message.sender.name.charAt(0) }}</span>
                </v-avatar>
              </v-col>

              <v-col>
                <p style="font-weight: bold; font-size: 1.1rem; margin: 0">{{ message.sender.name }}</p>
                <span style="color: lightslategrey; font-size: 0.9rem; margin: 0">{{ message.sentAt }}</span>
                <span>&nbsp;&nbsp;</span>

                <v-chip small color="warning" v-if="message.status === 0">Pending</v-chip>
                <v-chip small color="success" v-if="message.status === 1">Approved</v-chip>
                <v-chip small color="error" v-if="message.status === 2">Rejected</v-chip>

                <div class="trix-content" v-html="message.content" style="margin-top: 0.5rem"></div>

                <v-alert
                    v-if="message.rejectionComment"
                    style="margin-top: 1rem"
                    outlined
                    color="error"
                >
                  <p style="margin: 0; font-weight: bold">
                    Rejection Comment
                  </p>
                  <p style="margin: 0">{{ message.rejectionComment }}</p>
                </v-alert>

                <div style="margin-top: 1rem; margin-bottom: 0.6rem">
                  <v-textarea
                      v-if="message.isRejectionCommentInputVisible"
                      style="margin-bottom: -1rem"
                      outlined
                      rows="2"
                      v-model="message.newRejectionComment"
                      label="Reason for Rejection"
                  >
                  </v-textarea>

                  <template v-if="message.status === 0">
                    <v-btn
                        small
                        depressed
                        color="primary"
                        @click="approveMessage(message.id)"
                    >
                      Approve
                    </v-btn>

                    <v-btn
                        style="margin-left: 1rem"
                        small
                        depressed
                        color="error"
                        @click="rejectMessage(message)"
                    >
                      Reject
                    </v-btn>
                  </template>

                  <v-btn
                      v-else-if="message.status === 1"
                      small
                      depressed
                      color="error"
                      @click="rejectMessage(message)"
                  >
                    Change to Rejected
                  </v-btn>

                  <v-btn
                      v-else-if="message.status === 2"
                      small
                      depressed
                      color="success"
                      @click="approveMessage(message.id)"
                  >
                    Change to Approved
                  </v-btn>
                </div>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import {Api} from "@/services/api";

export default {
  name: 'MessageReviews',
  data: () => ({
    mentor: {},
    mentee: {},
    messages: [],
  }),
  created() {
    this.loadMessages();
  },
  methods: {
    loadMessages: async function () {
      const sessionId = this.$route.query.sessionId

      const response = await Api.get(`coordinator/getReviews?sessionId=${sessionId}`)

      response.messages.forEach((message) => {
        message.isRejectionCommentInputVisible = false
        message.newRejectionComment = ''
        message.sender = message.senderId === response.mentor.id ? response.mentor : response.mentee
      });

      this.messages = response.messages;
    },
    approveMessage: async function (messageId) {
      await Api.post('coordinator/approveMessage', {messageId, isApproved: true})
      await this.loadMessages()
    },
    rejectMessage: async function (message) {
      if (message.isRejectionCommentInputVisible === false) {
        message.isRejectionCommentInputVisible = true
        return
      }

      const body = {
        messageId: message.id,
        isApproved: false,
      }

      if (message.newRejectionComment !== '') {
        body.rejectionComment = message.newRejectionComment
      }

      await Api.post('coordinator/approveMessage', body)

      await this.loadMessages()
    }
  }
}
</script>
